import { template as template_5c842884a1644f2f8262d95fc3717c04 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_5c842884a1644f2f8262d95fc3717c04(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
