import { template as template_44c80986adfd49dda9f086291e1f421e } from "@ember/template-compiler";
const FKLabel = template_44c80986adfd49dda9f086291e1f421e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
