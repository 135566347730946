import { template as template_08126b5bf85d46a1b15604a60a3a60e3 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_08126b5bf85d46a1b15604a60a3a60e3(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
