import { template as template_3d0fa48ebe2a49cfa49e892cce31f3aa } from "@ember/template-compiler";
const FKControlMenuContainer = template_3d0fa48ebe2a49cfa49e892cce31f3aa(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
