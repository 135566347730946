import { template as template_0768053fde354625962b7c4ecd25fcdc } from "@ember/template-compiler";
const WelcomeHeader = template_0768053fde354625962b7c4ecd25fcdc(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
